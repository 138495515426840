import {getFoundationValues} from './getFoundationValues';
import {getPriceValue} from './blocks';
import {getDeliveryValues} from './getDeliveryValues';
import {getPorchValues} from './getPorchValues';
import {getTerraceValues} from './getTerraceValues';
import {getInputValues} from './getInputValues';
import {getVerandaValues} from './getVerandaValues';
import areaPriceCalculation from './areaPriceCalculation';
import {getStairsValues} from './getStairsValues';
import getData from "./getData";
import getOfferType from "./getOfferType";
import {initialHomeInstallationBlock} from "./initialBlock";

export function stairsPriceCalculation(block, blockValue, { region, houseId }) {
  const blockOption = block.values.options[0];

  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  const { priceValue } = getStairsValues(blockOption.value, region, houseId);

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
    const { width, steps } = blockPriceValues[key];
    const price = width * steps * priceValue;
    blockPriceValues[key].price = price;
    activeOptionsPrices.push(price);
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)) {
      sum += priceValue;
    }
    return sum;
  }, 0);

  return blockPriceValues;
}

export function inputPriceCalculation(block, blockValue, { region, houseId }) {
  const blockOptions = block.values.options;

  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
  });

  blockOptions.forEach((option, index) => {
    const { priceValue } = getInputValues(option.value, region, houseId);
    blockPriceValues[index].unit_price = priceValue;
    const inputValue = blockPriceValues[index].value;
    const price = priceValue * inputValue;
    blockPriceValues[index].price = price;
    activeOptionsPrices.push(price);
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)) {
      sum += priceValue;
    }
    return sum;
  }, 0);

  return blockPriceValues;
}

export function radioPriceCalculation(block, blockValue, { region, houseId }) {
  const blockPriceValues = {
    active_index: blockValue.active_index,
    totalPrice: 0
  };

  const blockOptions = block.values.options;
  blockOptions.forEach((option, index) => {
    blockPriceValues[index] = {};
    const price = getPriceValue(option.value, region, houseId);
    blockPriceValues[index].price = price;

    if (index === blockPriceValues.active_index) {
      const priceValue = Number(price);
      if (!isNaN(priceValue)) {
        blockPriceValues.totalPrice = priceValue;
      }
    }
  });

  return blockPriceValues;
}

export function checkboxesPriceCalculation(block, blockValue, { region, houseId }) {
  const blockOptions = block.values.options;

  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
  });

  blockOptions.forEach((option, index) => {
    const price = getPriceValue(option.value, region, houseId);
    blockPriceValues[index].price = price;

    if (blockPriceValues[index].checked) {
      activeOptionsPrices.push(price);
    }
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)) {
      sum += priceValue
    }
    return sum
  }, 0);

  return blockPriceValues;
}

export function foundationPriceCalculation(block, blockValue, { region, houseId, area, DDP_distance, blocks }) {
  const calculatedArea = typeof area !== "undefined" ?
                            areaPriceCalculation({ houseId, region, area, blocks }) :
                            0 ;

  const blockOptions = block.values.options;
  const instTimeOptionValue = blockOptions.find(option => (option['machine_name'] === '7')).value;
  const instTime = getFoundationValues(instTimeOptionValue, region, houseId).price.slice(0, -1);

  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
  });

  blockOptions.forEach((option) => {
    const machineName = option['machine_name'];
    let { price } = getFoundationValues(option.value, region, houseId);

    switch (machineName) {
      case '0':
        const selectIndex = parseInt(blockPriceValues[machineName].value);
        const selectOption = option.selections[selectIndex];
        const houseFoundationPrice = getFoundationValues(selectOption.value, region, houseId).price;
        if (selectIndex === 0) {
          price = parseFloat(houseFoundationPrice);
        } else {
          price = parseFloat(calculatedArea) + parseFloat(houseFoundationPrice);
        }
        break;

      case '1':
        //  TODO This option cannot be calculated without the previous one
        const foundationCost = blockPriceValues[0].price;
        price = foundationCost * (price / 100);
        break;

      case '2':
      case '4':
        price *= parseFloat(DDP_distance);
        break;

      case '3':
      case '5':
        const inputValue = blockPriceValues[machineName].value;
        price *= inputValue;
        break;

      case '6':
        if (!isNaN(Number(price))) {
          price *= instTime;
        }
        break;
    }

    if (getOfferType() === 'retail') {
      const foundation = blockPriceValues[0];

      if (foundation['value'] !== 0) {

        switch (machineName) {
          case '2':
          case '4':
          case '6': {
            const priceValue = Number(price);
            if (!isNaN(priceValue)) {
              foundation['price'] += priceValue;
              activeOptionsPrices.push(price);
            }
            delete blockPriceValues[machineName];
            return;
          }
        }
      }
    }

    blockPriceValues[machineName].price = price;
    if (blockPriceValues[machineName].checked) {
      activeOptionsPrices.push(price);
    }
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)){
      sum += priceValue;
    }
    return sum;
  }, 0);

  return blockPriceValues;
}

export function homeInstPriceCalculation(block, blockValue, { region, houseId, DDP_distance }) {
  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
  });

  const blockOptions = block.values.options;
  blockOptions.forEach((option) => {
    const id = option['machine_name'];
    let price = getPriceValue(option.value, region, houseId);

    switch (id) {
      case '2':
        price *= DDP_distance;
        break;

      case '3':
        const inputValue = blockPriceValues[id].value;
        price *= inputValue;
        break;

      case '4':
        const daysValueIndex = blockOptions.findIndex(option => (option['machine_name'] === '5'));
        const daysOptionValue = blockOptions[daysValueIndex].value;
        const days = getPriceValue(daysOptionValue, region, houseId).slice(0, -1);

        if (!isNaN(Number(price))) {
          price *= days;
        }
    }

    blockPriceValues[id].price = price;
    if (blockPriceValues[id].checked) {
      activeOptionsPrices.push(price);
    }
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)){
      sum += priceValue;
    }
    return sum;
  }, 0);

  return blockPriceValues;
}

export function deliveryPriceCalculation(block, blockValue, { region, houseId, DDP_distance, blocks, country }) {

  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
  });

  const blockOptions = block.values.options;
  const carOptionIndex = blockOptions.findIndex(option => (option['machine_name'] === '2'));
  const carOptionValue = blockOptions[carOptionIndex].value;
  const cars = getDeliveryValues(carOptionValue, region, houseId).price.slice(0, -1);

  blockOptions.forEach((option) => {
    let { price } = getDeliveryValues(option.value, region, houseId);
    const id = option['machine_name'];
    switch (id) {
      case '0':
        price = getDeliveryValues(option.value, region, houseId, country).price;
        price *= DDP_distance * cars;
        break;

      case '1':
        const inputValue = blockPriceValues[id].value;
        price *= inputValue * cars;
        break;
    }

    if (getOfferType() === 'retail' && id === '1') {
      if (typeof getData === "function") {
        const homeInstBlockData = getData()['blocks'].find( item => (
          item['machine_name'] === 'home_installation'
        ));
        const homeInstBlockValue = initialHomeInstallationBlock(homeInstBlockData, blocks);

        const homeInstPrices = homeInstPriceCalculation(homeInstBlockData, homeInstBlockValue, { region, houseId, DDP_distance });

        price += +homeInstPrices[3].price;
      }
    }

    blockPriceValues[id].price = price;
    if (blockPriceValues[id].checked) {
      activeOptionsPrices.push(price);
    }
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)){
      sum += priceValue;
    }
    return sum;
  }, 0);

  return blockPriceValues;
}

export function porchPriceCalculation(block, blockValue, { region, houseId }) {
  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
  });

  const blockOptions = block.values.options;

  blockOptions.forEach((option, index) => {
    const { unitPrice } = getPorchValues(option.value, region, houseId);
    const value = blockPriceValues[index].value;
    const price = unitPrice * value;
    blockPriceValues[index].unitPrice = unitPrice;
    blockPriceValues[index].price = price;

    if (blockPriceValues[index].checked) {
      activeOptionsPrices.push(price);
    }
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)) {
      sum += priceValue
    }
    return sum
  }, 0);

  return blockPriceValues;
}

export function terracePriceCalculation(block, blockValue, { region, houseId }) {
  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
  });

  const blockOptions = block.values.options;

  blockOptions.forEach((option, index) => {
    let { priceValue } = getTerraceValues(option.value, region, houseId);
    blockPriceValues[index].price = priceValue;

    if (index === 0) {
      const inputValue = blockPriceValues[index].value;
      blockPriceValues[index].unit_price = priceValue;
      priceValue *= inputValue;
      blockPriceValues[index].price = priceValue;
    }

    activeOptionsPrices.push(blockPriceValues[index].price);
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)) {
      sum += priceValue
    }
    return sum
  }, 0);

  return blockPriceValues;
}

export function checkboxInputPriceCalculation(block, blockValue, { region, houseId }) {
  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
  });

  const blockOptions = block.values.options;
  blockOptions.forEach((option) => {
    const index = option['machine_name'];
    let priceValue;
    switch (option.type) {
      case 'checkbox':
        priceValue = getPriceValue(option.value, region, houseId);
        break;

      case 'input':
        const unitPrice = getInputValues(option.value, region, houseId).priceValue;
        const inputValue = blockPriceValues[index].value;
        blockPriceValues[index].unit_price = unitPrice;
        priceValue = inputValue * unitPrice;
        break;
    }

    blockPriceValues[index].price = priceValue;
    if (blockPriceValues[index].checked){
      activeOptionsPrices.push(priceValue);
    }
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)) {
      sum += priceValue
    }
    return sum
  }, 0);

  return blockPriceValues;
}

export function verandaPriceCalculation(block, blockValue, { region, houseId }) {
  const blockPriceValues = {
    active_index: blockValue.active_index,
    totalPrice: 0
  };

  const blockOptions = block.values.options;
  blockOptions.forEach((option, index) => {
    blockPriceValues[index] = {};
    const { height, width, price } = getVerandaValues(option, region, houseId);
    if (width !== undefined && height !== undefined) {
      blockPriceValues[index].width = width;
      blockPriceValues[index].height = height;
    }

    blockPriceValues[index].price = price;

    if (index === blockPriceValues.active_index) {
      const priceValue = Number(price);
      if (!isNaN(priceValue)) {
        blockPriceValues.totalPrice = priceValue;
      }
    }
  });

  return blockPriceValues;
}

export function addBathroomPriceCalculation(block, blockValue, { region, houseId, blocks }) {
  const data = getData();
  const blockOptions = block.values.options;

  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
  });
  blockOptions.forEach((option, index) => {
    let { priceValue } = getInputValues(option.value, region, houseId);
    if (index === 0 &&
        blocks.hasOwnProperty('bathroom_complete_set')) {
      const bathSetBlockValue = blocks['bathroom_complete_set'];
      if (bathSetBlockValue.active_index === 1) {
        const bathSetBlock = data.blocks.find((item)=>(
          item.machine_name === 'bathroom_complete_set'
        ));
        const bathSetPrice = radioPriceCalculation(bathSetBlock, bathSetBlockValue, { region, houseId})[1].price;

        priceValue = Number(priceValue) + Number(bathSetPrice);
      }
    }
    blockPriceValues[index].unit_price = priceValue;
    const inputValue = blockPriceValues[index].value;
    blockPriceValues[index].price = priceValue * inputValue;

    activeOptionsPrices.push(blockPriceValues[index].price);
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)) {
      sum += priceValue
    }
    return sum
  }, 0);

  return blockPriceValues;
}

export function customOptionsPriceCalculation(block, blockValue) {
  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
    const price = Number(blockPriceValues[key].value);
    blockPriceValues[key].price = price;

    activeOptionsPrices.push(price);
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)) {
      sum += priceValue
    }
    return sum
  }, 0);

  return blockPriceValues;
}

export function radioCheckboxesPriceCalculation(block, blockValue, { region, houseId }) {
  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
  });

  blockPriceValues.active_index = blockValue.active_index;

  const blockOptions = block.values.options;
  blockOptions.forEach((option) => {
    const id = option['machine_name'];
    if (!blockPriceValues.hasOwnProperty(id)) {
      blockPriceValues[id] = {};
    }
    const price = getPriceValue(option.value, region, houseId);
    blockPriceValues[id].price = price;

    if (blockPriceValues[id].checked || id === blockPriceValues.active_index) {
      activeOptionsPrices.push(price);
    }
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)) {
      sum += priceValue
    }
    return sum
  }, 0);

  return blockPriceValues;
}

export function architecturalProjectCalculation(block, blockValue, { region, houseId }) {
  const blockPriceValues = {totalPrice: 0};
  const activeOptionsPrices = [];

  Object.keys(blockValue).forEach((key) => {
    blockPriceValues[key] = { ...blockValue[key] };
  });

  blockPriceValues.active_index = blockValue.active_index;

  const blockOptions = block.values.options;
  blockOptions.forEach((option) => {
    const id = option['machine_name'];
    if (!blockPriceValues.hasOwnProperty(id)) {
      blockPriceValues[id] = {};
    }
    blockPriceValues[id].price = getPriceValue(option.value, region, houseId);

    if (option.hasOwnProperty('sub_options')) {
      option['sub_options'].forEach(subOption => {
        const subId = subOption['machine_name'];
        if (!blockPriceValues.hasOwnProperty(subId)) {
          blockPriceValues[subId] = {};
        }
        blockPriceValues[subId].price = getPriceValue(subOption.value, region, houseId);
      });
    }
  });

  Object.keys(blockPriceValues).forEach(id => {
    const optionValues = blockPriceValues[id];

    if (id === blockPriceValues.active_index || optionValues.checked) {
      activeOptionsPrices.push(optionValues.price);
    }
  });

  blockPriceValues.totalPrice = activeOptionsPrices.reduce((sum, priceValue) => {
    priceValue = Number(priceValue);
    if (!isNaN(priceValue)) {
      sum += priceValue
    }
    return sum
  }, 0);

  return blockPriceValues;
}
